import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  lighten,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Section from "../components/Section";
import Map from "../components/Map";
import Link from "../components/Link";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles(({ palette, spacing, typography, breakpoints }) =>
  createStyles({
    memberships: {
      "& svg": {
        display: "block",
      },
    },
    membershipCard: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      border: "1px solid " + lighten(palette.primary.main, 0.5),
    },
    membership: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      textAlign: "center",
    },
    membershipHeader: {
      backgroundColor: lighten(palette.primary.main, 0.15),
    },
    membershipPrice: {
      padding: `${spacing(2)}px`,
      paddingTop: `${spacing(3)}px`,
    },
    membershipContent: {
      width: "100%",
      padding: "0",

      "&:last-child": {
        paddingBottom: "0",
      },
    },
    membershipTitle: {
      fontWeight: 600,
      padding: `${spacing(2)}px`,
      margin: 0,
    },
    membershipText: {
      fontSize: typography.pxToRem(15),
    },
    membershipActions: {
      flex: 0,
      justifyContent: "center",
      paddingBottom: "10px",
      marginBottom: `${spacing(3)}px`,
      marginTop: `${spacing(1.5)}px`,

      "& > a": {
        textDecoration: "none",
        color: "white",
      },
    },
    about: {
      fontSize: typography.pxToRem(16),
    },
    benefits: {
      "& svg": {
        display: "block",
      },
    },
    benefit: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: lighten(palette.primary.main, 0.96),
      border: "1px solid " + lighten(palette.primary.main, 0.5),
    },
    benefitInfo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
    },
    benefitContent: {
      "&:last-child": {
        paddingBottom: `${spacing(2)}px`,
      },
    },
    benefitTitle: {
      fontWeight: 600,
    },
    benefitText: {
      fontSize: typography.pxToRem(15),
    },
    benefitActions: {
      flex: 0,
      paddingLeft: spacing(1.5),
      paddingTop: 0,

      "& > a": {
        textDecoration: "none",

        "& span": {
          color: palette.primary.main,
        },
      },
    },

    campaign: {
      textAlign: "center",
      "& img": {
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",

        [breakpoints.only("xs")]: {
          width: "100%",
        },

        [breakpoints.only("sm")]: {
          width: "100%",
        },
      },
    },
    campaignHeader: {
      justifyContent: "center",
      paddingTop: "30px",
      "& h1": {
        marginBottom: "5px",
      },
    },
    campaignSubHeader: {
      "& h3": {
        fontWeight: "bold",
      },
      justifyContent: "center",
    },
    campaignCounter: {
      justifyContent: "center",
      padding: "10px 0",
      fontSize: 25,
      fontWeight: "bold",
    },
    campaignText: {
      justifyContent: "center",
      margin: "5px 0 0 0",
    },
    campaignButton: {
      justifyContent: "center",
      paddingTop: "10px",
    },

    signupButton: {
      marginTop: 8,

      "& a": {
        textDecoration: "none",
        color: "white",
      },
    },

    bigLink: {
      fontSize: "1.5rem",
    },

    personalTrainer: {
      "& img": {
        width: "100%",
      },
      backgroundColor: "#ffffff",
      border: "1px solid " + lighten(palette.primary.main, 0.5),
      borderRadius: "4px",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    personalTrainerText: {
      padding: spacing(4),

      [breakpoints.only("xs")]: {
        padding: spacing(2),
      },

      "& a": {
        textDecoration: "none",
        color: palette.primary.main,
        width: "100%",
      },
    },
    personalTrainerTitle: {
      fontWeight: 600,
      marginBottom: "0.35em",
    },
  })
);

interface Membership {
  id: number;
  title: string;
  price: string;
  description: React.ReactNode;
  target: "signup" | "form";
}

interface Benefit {
  title: string;
  text: string;
  image: string;
  link?: string;
}

const getMemberships: (t: any) => Array<Membership> = (t) => [
  {
    id: 32,
    title: t("start.memberships.running.name"),
    price: `369,- / ${t("start.memberships.month")}`,
    description: (
      <div>
        {t("start.memberships.running.desc_line1")}
        <br />
        {t("start.memberships.running.desc_line2")}
        <br />
        {t("start.memberships.running.desc_line3")}
      </div>
    ),
    target: "signup",
  },
  {
    id: 16,
    title: t("start.memberships.one_month.name"),
    price: "449,-",
    description: (
      <div>
        {t("start.memberships.one_month.desc_line1")}
        <br />
        <br />
        {t("start.memberships.one_month.desc_line2")}
      </div>
    ),
    target: "signup",
  },
  {
    id: 17,
    title: t("start.memberships.one_week.name"),
    price: "299,-",
    description: <div>{t("start.memberships.one_week.desc")}</div>,
    target: "signup",
  },
  {
    id: 0,
    title: t("start.memberships.drop-in.name"),
    price: "149,-",
    description: <div>{t("start.memberships.drop-in.desc")}</div>,
    target: "form",
  },
  {
    id: 0,
    title: t("start.memberships.trial.name"),
    price: t("start.memberships.trial.price"),
    description: <div>{t("start.memberships.trial.desc")}</div>,
    target: "form",
  },
];

const getBenefits: (t: any) => Array<Benefit> = (t) => [
  {
    title: t("start.benefits.opening_hours.title"),
    text: t("start.benefits.opening_hours.text"),
    image: "hours",
    link: "/generell-info#apningstider",
  },
  {
    title: t("start.benefits.price.title"),
    text: t("start.benefits.price.text"),
    image: "price",
    link: "/#medlemskap",
  },
  {
    title: t("start.benefits.freeze.title"),
    text: t("start.benefits.freeze.text"),
    image: "freeze",
    link: "/generell-info#frysing",
  },
  {
    title: t("start.benefits.parking.title"),
    text: t("start.benefits.parking.text"),
    image: "parking",
    link: "/lokalet#parkering",
  },
  {
    title: t("start.benefits.shop.title"),
    text: t("start.benefits.shop.text"),
    image: "shop",
    link: "/tjenester#butikk",
  },
  {
    title: t("start.benefits.equipment.title"),
    text: t("start.benefits.equipment.text"),
    image: "equipment",
    link: "/lokalet#utstyr",
  },
  {
    title: t("start.benefits.functional-fitness-area.title"),
    text: t("start.benefits.functional-fitness-area.text"),
    image: "functional-fitness-area",
    link: "/lokalet#utstyr",
  },
  {
    title: t("start.benefits.freeweights.title"),
    text: t("start.benefits.freeweights.text"),
    image: "freeweights",
    link: "/lokalet#utstyr",
  },
  {
    title: t("start.benefits.environment.title"),
    text: t("start.benefits.environment.text"),
    image: "environment",
    link: "/rent-senter",
  },
  {
    title: t("start.benefits.service.title"),
    text: t("start.benefits.service.text"),
    image: "service",
  },
];

const Start = () => {
  const classes = useStyles();

  const { protocol, host } = window.location;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("start.title")}</title>
      </Helmet>

      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop
        autoPlay
        interval={5000}
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <picture key={i}>
            <source
              media="(orientation: portrait) and (max-width: 375px)"
              srcSet={`${process.env.PUBLIC_URL}/images/promo/portrait/${i}/w_375.jpg`}
            />
            <source
              media="(orientation: portrait) and (max-width: 563px)"
              srcSet={`${process.env.PUBLIC_URL}/images/promo/portrait/${i}/w_563.jpg`}
            />
            <source
              media="(orientation: portrait) and (max-width: 750px)"
              srcSet={`${process.env.PUBLIC_URL}/images/promo/portrait/${i}/w_750.jpg`}
            />
            <source
              media="(orientation: landscape) and (max-width: 750px)"
              srcSet={`${process.env.PUBLIC_URL}/images/promo/landscape/${i}/w_750.jpg`}
            />
            <source
              media="(orientation: landscape) and (max-width: 1125px)"
              srcSet={`${process.env.PUBLIC_URL}/images/promo/landscape/${i}/w_1125.jpg`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/promo/landscape/${i}/w_1500.jpg`}
              alt="Promo"
            />
          </picture>
        ))}
      </Carousel>

      {true && (
        <Section>
          <Grid container xs={12} className={classes.campaign}>
            <Grid item container xs={12}>
              <img
                src={`${process.env.PUBLIC_URL}/images/99-til-march.png`}
                alt="Tren for 99,- til mars"
              />
            </Grid>

            <Grid item container xs={12} className={classes.campaignButton}>
              <div className={classes.signupButton}>
                <Button
                  href={`${process.env.REACT_APP_SIGNUP_URL}?id=72&returnUrl=${
                    protocol + "//" + host
                  }`}
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {t("start.sign_up_button_text")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Section>
      )}

      <Section title={t("start.memberships.section_title")}>
        <Grid
          id="medlemskap"
          className={classes.memberships}
          container
          justify="center"
          spacing={4}
        >
          {getMemberships(t).map(
            ({ id, title, price, description, target }) => (
              <Grid
                key={title}
                item
                container
                xs={12}
                sm={6}
                md={4}
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Card className={classes.membershipCard}>
                  <div className={classes.membership}>
                    <CardContent className={classes.membershipContent}>
                      <div className={classes.membershipHeader}>
                        <Typography
                          gutterBottom
                          component="h3"
                          variant="h6"
                          className={classes.membershipTitle}
                        >
                          {title}
                        </Typography>
                      </div>

                      <div className={classes.membershipPrice}>
                        <Typography
                          style={{ fontSize: 22, fontWeight: 600 }}
                          color="textPrimary"
                          className={classes.membershipText}
                        >
                          {price}
                        </Typography>

                        <br />

                        <Typography
                          variant="body2"
                          component="div"
                          color="textSecondary"
                          className={classes.membershipText}
                        >
                          {description}
                        </Typography>
                      </div>
                    </CardContent>
                  </div>

                  <CardActions className={classes.membershipActions}>
                    <Button
                      href={
                        target === "signup"
                          ? `${
                              process.env.REACT_APP_SIGNUP_URL
                            }?id=${id}&returnUrl=${protocol + "//" + host}`
                          : "/bestill-provetime"
                      }
                      size="large"
                      color="primary"
                      variant="contained"
                    >
                      {target === "signup"
                        ? t("start.sign_up_button_text")
                        : t("start.order_button_text")}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </Section>

      <Section title={t("start.benefits.section_title")}>
        <Grid
          className={classes.benefits}
          container
          justify="center"
          spacing={4}
        >
          {getBenefits(t).map(({ title, text, image, link }) => (
            <Grid
              key={title}
              item
              container
              xs={12}
              sm={6}
              md={4}
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Card className={classes.benefit}>
                <div className={classes.benefitInfo}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${process.env.PUBLIC_URL}/images/benefits/${image}.jpg`}
                    title={title}
                    alt={title}
                  />

                  <CardContent className={classes.benefitContent}>
                    <Typography
                      gutterBottom
                      component="h3"
                      variant="h6"
                      className={classes.benefitTitle}
                    >
                      {title}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.benefitText}
                    >
                      {text}
                    </Typography>
                  </CardContent>
                </div>

                {link && (
                  <CardActions className={classes.benefitActions}>
                    <Link to={link}>
                      <Button size="small" color="primary">
                        {t("start.read_more")}
                      </Button>
                    </Link>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Section>

      <Section title={t("start.personal_trainer.section_title")}>
        <Grid container className={classes.personalTrainer}>
          <Grid item container xs={12} sm={12} md={6}>
            <img
              src={`${process.env.PUBLIC_URL}/images/personal-trainer.jpg`}
              alt={t("start.personal_trainer.image_alt")}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            className={classes.personalTrainerText}
          >
            <Typography
              className={classes.personalTrainerTitle}
              variant="h6"
              component="h3"
            >
              {t("start.personal_trainer.work")}
            </Typography>

            <Typography variant="body1" paragraph>
              {t("start.personal_trainer.benefits")}
            </Typography>

            <Typography variant="body1" paragraph>
              {t("start.personal_trainer.potential")}
            </Typography>

            <Link to="/personlig-trener">{t("start.read_more")}</Link>
          </Grid>
        </Grid>
      </Section>

      <Section title={t("start.about.section_title")}>
        <div className={classes.about}>
          <Typography variant="body1" paragraph>
            {t("start.about.general")}
          </Typography>

          <Typography variant="body1" paragraph>
            {t("start.about.venue")}
          </Typography>

          <Typography variant="body1" paragraph>
            {t("start.about.equipment")}
          </Typography>

          <Typography variant="body1" paragraph>
            {t("start.about.price")}
          </Typography>
        </div>

        <Map />
      </Section>
    </>
  );
};

export default Start;
